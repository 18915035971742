// =======================================================================
// Header menu
// =======================================================================
$('.header-nav-burger').click(function() {
    $('.header-nav-mobile').toggleClass('active');
});
$('.header-nav-close').click(function() {
    $('.header-nav-mobile').removeClass('active');
});
$('.header-nav-mobile > ul li').click(function() {
    $(this).find('ul').toggle();
});





if(document.querySelectorAll('.header .header-nav-bottom .menu-item-has-children'))
{
	for(let menuItemWithSubnav of document.querySelectorAll('.header .header-nav-bottom .menu-item-has-children'))
	{
		let thisSubmenuId = menuItemWithSubnav.getAttribute('data-menu'),
			thisSubmenu = document.getElementById(thisSubmenuId),
			submenus = document.querySelectorAll('.header-sub-menu');

		menuItemWithSubnav.addEventListener('mouseover', event =>
		{
			event.stopPropagation();

			//Hide all submenus
			for(let submenu of submenus)
			{
				submenu.style.display = 'none';
			}

			if(thisSubmenu)
			{
				thisSubmenu.style.display = 'block';
			}
		});
			
		thisSubmenu.addEventListener('mouseleave', event =>
		{
			event.stopPropagation();

			//Hide all submenus
			for(let submenu of submenus)
			{
				submenu.style.display = 'none';
			}

			if(thisSubmenu)
			{
				thisSubmenu.style.display = 'none';
			}
		});
	}
}


$('.header .header-nav-top .header-nav > ul > li.menu-item-has-children').mouseenter(function() {
	$(this).find('> ul').show();
});

$('.header .header-nav-top .header-nav > ul > li.menu-item-has-children').mouseleave(function() {
	$(this).find('> ul').hide();
});


// =======================================================================
// Accordion
// =======================================================================
var accordion = ".accordion";

if($(accordion).length > 0) {
	$(accordion + ' li .accordion-tab').click(function() {
		$(this).parent('li').toggleClass('active');

		//Set aria for accessibility
		$(this).parent('li').find('.accordion-content').attr('aria-expanded', function (i, attr) {
		    return attr == 'true' ? 'false' : 'true'
		});
	});
}



// =======================================================================
// Colorbox lightbox
// =======================================================================
$(".colorbox a, .gallery a").colorbox({
	maxWidth: "90%",
	maxHeight: "90%",
	rel: "colorbox-images",
	photo: true,
	scrolling: false
});



// =======================================================================
// Carousel
// =======================================================================
var carousel = ".carousel";

if($(carousel).length > 0) {
	$(carousel).flexslider({
		animation: "slide",
		slideshow: true,
		slideshowSpeed: 5000,
		animationLoop: true,
		controlNav: true,
		directionNav: false,
		touch: true,
		smoothHeight: false
	});
}


// =======================================================================
// Google Maps
// =======================================================================
var center,
	icon = null,
	map = null,
	markersArray = [],
	circle = null,
	infowindow,
	locationMarker,
	marker,
	i;

var GoogleMap = {
	loadSingleMarkerMap: function(latitude, longitude, mapID) {

		if(mapID) {
			mapID = mapID;
		} else {
			mapID = 'googleMap';
		}

		var lat = (latitude ? latitude : 54.309313);
		var lng = (longitude ? longitude : -2.548828);

	    var map = new google.maps.Map(document.getElementById(mapID), {
			zoom: 17,
			center: new google.maps.LatLng(lat, lng)
		});

	    var icon = {
	        anchor: new google.maps.Point(10, 28),
	        scaledSize: new google.maps.Size(20, 28),
	        size: new google.maps.Size(20, 28),
	        url: '/wp-content/themes/hertford/images/svgs/map-marker.svg'
	 	};

	 	marker = new google.maps.Marker({
			position: new google.maps.LatLng(lat, lng),
	        icon: icon,
			animation: google.maps.Animation.DROP,
			map: map
	    });
	}
}

//Display single marker Google map
var singleMarkerGoogleMap = $('.google-map.single-marker');

if(singleMarkerGoogleMap.length) {
	singleMarkerGoogleMap.each(function() {
		var id = $(this).attr('id'),
			lat = $(this).attr('data-lat'),
			lng = $(this).attr('data-lng');

		if(lat && lng) {
			GoogleMap.loadSingleMarkerMap(lat, lng, id);
		}
	});
}


// =======================================================================
// Tab pane
// =======================================================================
var tabPane = {
	selector: ".tab-pane",
	tab: ".tab-pane-tabs li",
	pane: ".tab-pane-content li",
	showHidePanes: function(activePane) {
		$(this.tab).removeClass('active');
		$(this.tab + '[data-pane-section=' + activePane + ']').addClass('active');

		$(this.pane).hide();
		$(this.pane + '[data-pane-section=' + activePane + ']').fadeIn(300);
	}
};

$(tabPane.selector).each(function() {
	$(this).find('.tab-pane-tabs li').click(function() {
		var tabPaneName = $(this).data('pane-section');

		tabPane.showHidePanes(tabPaneName);
	});
});