// ======================================================================= 
// Events
// =======================================================================

$(window).bind("scroll", function() {
    
    //Parallax panel images
    if($(".parallax-element").length) {
        
        $('.parallax-element').each(function() {
            var parallaxElement = $(this);

            window.requestAnimationFrame(function() {
                parallaxElement.css('transform','translateY(' + ($(this).scrollTop() * 0.8) + 'px)');
            });
        });
        
    }

});


//Gift aid calculator
$('#gift-aid-button').click(function() {
    var giftAidFields = {
        donationField: Number($('#gift-aid-donation-field').val()),
        taxRateField: Number($('#gift-aid-tax-rate-field').val()),
        donationTaxRatePercentageAmount: Number($('#gift-aid-donation-field').val() / 100 * 20),
        donationGiftAidPercentageAmount: Number($('#gift-aid-donation-field').val() / 100 * 5),
        fullText: $('#gift-aid-full-text'),
        higherText: $('#gift-aid-higher-text'),
        fullTextAnswer: $('#gift-aid-full-value'),
        higherTextAnswer: $('#gift-aid-higher-value')
    };

    var giftAidAmount = giftAidFields.donationTaxRatePercentageAmount + giftAidFields.donationGiftAidPercentageAmount,
        fullTextAnswerValue = Number(giftAidFields.donationField + giftAidAmount).toFixed(2);

    console.log(giftAidFields.taxRateField);

    if(giftAidFields.taxRateField < 20 || giftAidFields.taxRateField > 80) {
        alert('The basic tax rate must be between 20 and 80 percent')
    }

    if(giftAidFields.donationField && giftAidFields.taxRateField >= 20) {
        giftAidFields.fullText.removeClass('hide');
        giftAidFields.fullTextAnswer.html(fullTextAnswerValue);

        giftAidFields.higherText.removeClass('hide');
        giftAidFields.higherTextAnswer.html(Number(giftAidAmount).toFixed(2));
    }
});


//Open subnav on mobile menu top level links, don't take user to page
$('.header-nav-mobile > ul > .menu-item-has-children > a').click(function(event) {
    event.preventDefault();
});


//Header search form
$('.header-search-icon').click(function() {
    $('.header-search-form').slideToggle(300).find('.input').focus();
});


//Donations chart
$('.donations-segment').click(function() {
	var color = $(this).data('color');

	$('.donations-segment').removeClass('active');
	$(this).addClass('active');

	$('.donations-segment-text').removeClass('active');
	$('.donations-segment-' + color +'-text').addClass('active');
});


//Form validation
$('.validated-form-button').click(function(event) {
    event.preventDefault();
    var sendForm = '';

    $(this).closest('form').find("select.required, input.required, textarea.required").each(function(index) {
        if($(this).val() == "") {
            $(this).addClass('error');
            sendForm += 'error';
        } else {
            $(this).removeClass('error');
            sendForm += '';
        }
    });

    if(sendForm == '') {
        $('#requiredFieldsNotice').hide();
        $(this).closest('.validated-form').submit();
    } else {
        $(this).before('<p id="requiredFieldsNotice" class="alert fail hide">Please fill in all required fields.</p>');
        $('#requiredFieldsNotice').show();
    }

});


//Add error class to required fields on blur
$("select.required, input.required, textarea.required").blur(function() {
    if($(this).val() == '') {
        $(this).addClass('error');
    } else {
        $(this).removeClass('error');
    }
});


// //Stick sidebar navigation to top of window on scroll down
// var stickyElement = $('.page-navigation'),
//     stickyElementActiveClass = 'active',
//     headerHeight = $('.header').outerHeight(true);
//
// if(stickyElement.length > 0) {
//     $(window).on("load scroll", function(event) {
//
//         console.log(headerHeight);
//
//         if($(window).scrollTop() > (headerHeight + 30)) {
//             if(!stickyElement.hasClass(stickyElementActiveClass)) {
//                 stickyElement.addClass(stickyElementActiveClass);
//             }
//         } else {
//             stickyElement.removeClass(stickyElementActiveClass);
//         }
//     });
// }